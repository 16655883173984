.d-flex {
  display: flex !important;
}

html,
body,
#app,
#app>div {
  height: 100%;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.pkt-cursor-pointer {
  cursor: pointer;
}

.pkt-divider {
  background-color: rgba(0, 0, 0, 0.08);
  line-height: 1px;
}

.iconclr {
  color: #5655a8;
}

.pkt-text-undeline {
  text-decoration: underline;
  color: #0d6efd;
}

.pkt-text-underline {
  text-decoration: underline;
}

.pkt-font-bold {
  font-weight: 600;
}

.pkt-color-red {
  color: #d32f2f;
}

.pkt-img-div {
  border: 1px solid #b0b2b5;
  padding: 10px;
  border-radius: 5px;
  margin-right: 10px;
}

.pkt-approve-text {
  color: #1976d2;
  font-style: italic;
}

.react-thumbnail-generator {
  height: 100px;
  width: 100px;
}

.pkt-font-italic {
  font-style: italic;
}

.pkt-success-dialog>.MuiDialog-container>.MuiPaper-root {
  background: #14a44d;
  border-radius: 16px;
}

.pkt-main-height {
  height: calc(100% - 45px) !important;
}

.pkt-success-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiDialogContentText-root {
  color: black;
  font-weight: 300;
}

.pkt-success-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogActions-root>.MuiButton-root {
  background: #171e00;
}

.pkt-error-dialog>.MuiDialog-container>.MuiPaper-root {
  background: #ffdad6;
  border-radius: 16px;
}

.pkt-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogTitle-root {
  color: #410002;
}

.pkt-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogContent-root>.MuiDialogContentText-root {
  color: #410002;
  font-weight: lighter;
}

.pkt-error-dialog>.MuiDialog-container>.MuiPaper-root>.MuiDialogActions-root>.MuiButton-root {
  background: #410002;
}

.MuiTablePagination-displayedRows {
  padding-top: 16px !important;
}

.MuiTablePagination-selectLabel {
  padding-top: 14px !important;
}

.pkt-error {
  color: #d32f2f;
  font-size: 12px !important;
  padding: 0 5px;
}

@for $i from 0 to 101 {
  .pkt-width-percentage-#{$i} {
    width: #{$i}#{"%"} !important;
  }

  .pkt-height-percentage-#{$i} {
    height: #{$i}#{"%"} !important;
  }

  .pkt-max-width-percentage-#{$i} {
    max-width: #{$i}#{"%"} !important;
  }

  .pkt-max-height-percentage-#{$i} {
    max-height: #{$i}#{"%"} !important;
  }
}

@for $i from 1 to 1000 {
  .pkt-width-#{$i} {
    width: $i + px !important;
  }

  .pkt-height-#{$i} {
    height: $i + px !important;
  }

  .pkt-max-width-#{$i} {
    max-width: $i + px !important;
  }

  .pkt-max-height-#{$i} {
    max-height: $i + px !important;
  }

  .pkt-min-width-#{$i} {
    min-width: $i + px !important;
  }

  .pkt-min-height-#{$i} {
    min-height: $i + px !important;
  }

  .pkt-height-vh-#{$i} {
    height: $i + vh !important;
  }

  .pkt-font-size-#{$i} {
    font-size: $i + px !important;
  }

  .pkt-font-weight-#{$i} {
    font-weight: $i !important;
  }
}

@for $i from -100 to 450 {
  .pkt-width-#{$i} {
    width: $i + px !important;
  }

  .pkt-height-#{$i} {
    height: $i + px !important;
  }

  .pkt-margin-#{$i} {
    margin: $i + px !important;
  }

  .pkt-margin-right-#{$i} {
    margin-right: $i + px !important;
  }

  .pkt-margin-left-#{$i} {
    margin-left: $i + px !important;
  }

  .pkt-margin-top-#{$i} {
    margin-top: $i + px !important;
  }

  .pkt-margin-bottom-#{$i} {
    margin-bottom: $i + px !important;
  }

  .pkt-padding-#{$i} {
    padding: $i + px !important;
  }

  .pkt-padding-right-#{$i} {
    padding-right: $i + px !important;
  }

  .pkt-padding-left-#{$i} {
    padding-left: $i + px !important;
  }

  .pkt-padding-top-#{$i} {
    padding-top: $i + px !important;
  }

  .pkt-padding-bottom-#{$i} {
    padding-bottom: $i + px !important;
  }
}

.pkt-overflow-hidden {
  overflow: hidden !important;
}

.pkt-overflow-x-auto {
  overflow-x: auto !important;
}

.pkt-overflow-y-auto {
  overflow-y: auto !important;
}

.upload-field {
  border: 1px dashed black;
  // margin: 24px;
  padding: 16px;
  border-radius: 8px;
}